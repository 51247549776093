@import '@/styles/style.scss';

.our-technology-page{
  justify-content: center;
  padding-left: rem(1);
  padding-right: rem(1);

  .subtitle {
    font-size: rem(22);
    line-height: rem(28);
    font-weight: 300;
  }
  p {
    text-align: justify;
  }

  h3 {
    display: flex;
    text-align: center;
  }

  h4 {
    font-size: rem(22);
  }

  .animated-container {
    padding-top: 30px;
    justify-content: space-between;
    .image-container {
      display: flex;
      width: 100%;
      align-self: center;
      align-items: center;
      img {
        width: 100%;
      }
  }
   .paragraph-container {
      width: 100%;
      p {
        text-align: justify;
      }
    } 

  }
}

 