@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.form-input {
  &.textarea {
    height: 136px;
  }
}

.contact-us-view {

  padding: rem(70) 0;
  background-color: $primaryColor;
  color: $white;
  text-align: center;

  .title {
    font-size: rem(23);
    line-height: rem(25);
    font-weight: 600;
    margin: 0;
    padding: 0 0 rem(15) 0;
  }

  .form {
    padding: rem(20) rem($padding) rem(10) rem($padding);
    margin: 0 auto;
  }
}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .contact-us-view {

    min-width: 320px;

    .form {
      max-width: 700px;
      padding: rem(40) 0 rem(20) 0;
    }

  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .animated-component {

    &.active .contact-us-view {
      opacity: 1;
    }

    .contact-us-view {
      opacity: 0;
      transition: opacity 0.6s ease;
    }

  }

  .contact-us-view {

    padding: rem(80) 0;

    .title {
      font-size: rem(35);
      line-height: rem(40);
      padding: 0;
    }

  }

}