@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.button {
  background-color: $buttonColor;
  border: rem(2) solid $buttonColor;
  border-radius: rem(999);
  color: $buttonTextColor;
  cursor: pointer;
  display: inline-block;
  padding: rem(10) rem(20);
  text-align: center;
  text-decoration: none !important;
  transition: background-color 0.4s ease, border 0.4s ease;
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(16);

  &.hide-border {
    border: 0;
  }

  &--disabled,
  &--disabled:hover,
  &--disabled:focus,
  &--disabled:active {
    border: rem(2) solid $lighter-grey !important;
    background-color: $buttonDisabledColor !important;
    color: $buttonDisabledTextColor !important;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    outline:0;
    background-color: $buttonHoverColor;
    border-color: $buttonHoverColor;
    color: $white;
  }

  &--white {
    border-color: $white;
  }
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .button {
    padding: rem(12) rem(25);
  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .button {
    font-size: rem(15);
    line-height: rem(17);
  }

}