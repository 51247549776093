@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.image-col {

  width: 100%;

  img {
    display: block;
    width: 100%;
  }

}