@import '@/styles/imports/variables.scss';

.home-paragraph {
  margin: 30px auto;
  text-align: justify;
  padding: 0 20px;
}

.image-container {
  display: flex;
  justify-content: center;

  .home-image {
    width: 50%;
    min-width: 300px;
    padding: 20px;

    img {
      display: block;
      width: 100%;
    }
  }
}

.image-content-col__right {
  margin: 0 !important;
}

@media screen and (min-width: map-get($grid-breakpoints, 'xs')) {
  .home-paragraph {
    max-width: 550px;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .home-paragraph {
    max-width: 600px;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .home-paragraph {
    max-width: 57rem;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .home-paragraph {
    max-width: 970px;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
  .home-paragraph {
    max-width: 1250px;
  }
}