@function rem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin clear-default-appearance {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;
  margin: 0;
  text-align: left;
}

@mixin reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}