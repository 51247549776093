@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.content-col {

  width: 100%;
  text-align: flex-start;

  &__logo {

    width: 100%;

    svg {
      width: rem(245);
      height: rem(30);
    }
  }

  &__title {
    color: $primaryColor;
    margin: 0;
    padding: 0;
    text-align: flex-start;
  }

  &__subtitle {
    color: $primaryColor;
    font-weight: 250;
    margin: 0;
    padding: rem(15) 0 0 0;
  }

  &__body ul {

    @include reset-list;
    width: 100%;
    padding: rem(30) 0;

    li {
      @include reset-list;
      position: relative;
      text-align: left;
      padding: 0 0 rem(20) rem(20);
      font-weight: 300;

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        content: '';
        background-color: $secondaryColor;
        border-radius: rem(999);
        position: absolute;
        left: 0;
        top: rem(10);
        display: block;
        width: rem(6);
        height: rem(6);
      }
    }

  }

  &__cta {
    width: 100%;
    padding: rem(25) 0 0 0;

    &-item {
      width: 100%;
      margin: 0 0 rem(15) 0;

      &:last-child {
        margin: 0;
      }
    }

  }

  &--hero {

    .content-col__title {
      font-size: rem(38);
      line-height: rem(40);
      font-weight: 300;

      span {
        font-weight: 600;
      }

    }

    .content-col__subtitle {
      font-size: rem(17);
      line-height: rem(25);
      padding: rem(20) 0 0 0;
    }

  }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .content-col {

    text-align: left;

    &__logo {
      margin: 0 0 rem(5) 0;

      svg {
        width: rem(335);
        height: rem(40);
      }
    }

    &__body ul {

      li {
        font-size: rem(18);
        line-height: rem(27);
        padding: 0 0 rem(20) rem(25);

        &:before {
          width: rem(8);
          height: rem(8);
        }
      }
    }

    &__cta {

      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;

      &-item {
        width: auto;
        margin: 0 rem(15) rem(15) 0;
      }

    }

    &--hero {

      .content-col__cta {
        padding: rem(35) 0 0 0;
      }

    }

  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .content-col {

    &__logo {

      margin: 0;

      svg {
        width: rem(390);
        height: rem(45);
      }
    }

    &__subtitle {
      padding: rem(20) 0 0 0;
    }

    &--hero {

      .content-col__title {
        font-size: rem(43);
        line-height: rem(44);
      }

      .content-col__subtitle {
        font-size: rem(20);
        line-height: rem(28);
        padding: rem(20) 0 0 0;
      }

    }

  }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .content-col {

    &__logo {

      margin: 0;

      svg {
        width: rem(525);
        height: rem(60);
      }
    }

    &__subtitle {
      padding: rem(30) 0 0 0;
    }

    &__cta {
      padding: rem(30) 0 0 0;

      &-item {
        margin: 0 rem(25) 0 0;
      }

    }

    &--hero {

      .content-col__title {
        font-size: rem(60);
        line-height: rem(61);
      }

      .content-col__subtitle {
        font-size: rem(24);
        line-height: rem(34);
        padding: rem(30) rem(30) 0 0;
      }

      .content-col__cta {
        padding: rem(45) 0 0 0;
      }

    }

  }

}
