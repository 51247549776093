@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.footer-links {

  width: 100%;

  &__title {
    font-size: rem(17);
    line-height: rem(21);
    margin: 0;
    padding: 0 0 rem(20) 0;
    font-weight: 700;
    color: $primaryColor;
  }

  ul {
    @include reset-list;
    width: 100%;

    li {
      @include reset-list;
      width: 100%;
      font-size: rem(15);
      line-height: rem(22);
      padding: 0 0 rem(10) 0;

      &:last-child {
        padding: 0;
      }

      a,
      a.nav-link {
        color: $primaryColor;
        font-weight: 300;
        padding: 0;
        margin: 0;
      }
    }
  }


}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .footer-links {

    ul li {

      a,
      a.nav-link {

        &:hover,
        &:focus {
          outline: 0;
          text-decoration: underline;
        }

      }
    }

  }

}