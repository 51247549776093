@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.privacy-policy-page {
  padding: rem(40) 0 rem(80);

  .privacy-policy-wrapper {

    .title {
      font-size: rem(45);
      margin-bottom: rem(69);
      padding: 0 rem(20);
      text-align: center;
      font-weight: 600;
    }

  }

  .item-title {
    font-weight: 600;
    padding: rem(20) 0 rem(15) 0;
  }

}
