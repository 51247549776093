@import '@/styles/style.scss';

.our-technology-page{
  justify-content: center;
  padding-left: rem(1);
  padding-right: rem(1);

  p {
    text-align: justify;
  }

  .animated-container {
    padding-top: 30px;
    justify-content: space-between;
    .image-container {
      display: flex;
      width: 100%;
      align-self: center;
      align-items: center;
      img {
        width: 100%;
      }
  }
   .paragraph-container {
      width: 100%;
      p {
        text-align: justify;
      }
    } 

  }
}

 