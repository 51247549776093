@import './imports/variables';
@import './imports/utils';
@import './imports/typography';

::-moz-selection {
  color: $white;
  background: $secondaryColor;
}

::selection {
  color: $white;
  background: $secondaryColor;
}

body {
  margin: 0;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px;
  color: $primaryColor;

  * {
    font-family: $primary-font !important;
  }

}

.component-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  margin-bottom: 35px;

  &__inner {
    width: 100%;
    padding: 0 rem($padding);
    max-width: rem($max-width);
  }

}

.main-title {
  font-size: rem(22);
  line-height: rem(28);
  font-weight: 300;
}

.secondary-title {
  font-size: rem(15);
  line-height: rem(24);
}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

  .component-wrapper {

    &__inner {
      max-width: map-get($container-max-widths, 'sm');
    }

  }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .component-wrapper {

    &__inner {
      max-width: map-get($container-max-widths, 'md');
    }

  }

  .main-title {
    font-size: rem(26);
    line-height: rem(36);
    font-weight: 300;
  }

  .secondary-title {
    font-size: rem(16);
    line-height: rem(26);
  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .component-wrapper {

    &__inner {
      max-width: map-get($container-max-widths, 'lg');
    }

  }

  .main-title {
    font-size: rem(32);
    line-height: rem(38);
    font-weight: 300;
  }

  .secondary-title {
    font-size: rem(18);
    line-height: rem(27);
  }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .component-wrapper {

    &__inner {
      max-width: map-get($container-max-widths, 'xl');
    }

  }

}
