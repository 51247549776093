@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.language-selector {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;

  &__arrow {

    width: rem(12);
    height: rem(12);
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    svg {
      width: rem(12);
      height: rem(12);
      min-width: rem(12);
      min-height: rem(12);
      color: $primaryColor;
      margin: 0 0 0 rem(15);
    }

    &.active svg {
      transform: rotate(180deg);
    }

  }

  & > svg {
    width: rem(20);
    height: rem(20);
    color: $primaryColor;
    margin: 0 rem(10) 0 0;
  }

  .popover {
    background-color: transparent;
    border: none;
    position: relative;
    font-size: rem(14);
    line-height: rem(14);
  }

  .language-display {
    align-items: center;
    cursor: pointer;
    display: flex;

    .icon {
      font-size: rem(18);
      margin-right: rem(8);
    }

    .language-selected {
      font-size: rem(14);
      line-height: rem(14);
    }
  }
}

.select-lang-wrapper {
  background-color: $white;
  padding: rem(10) rem(15);
  width: rem(190);

  .select-lang-title {
    padding: 5px 0;
    text-align: center;
  }

  .select-lang-title,
  .select-lang-item {
    font-size: rem(14);
    line-height: rem(21);
  }

  .select-lang-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: rem(5) rem(10);

    &:hover {
      background-color: rgba($black, 0.1);
    }
  }

  .select-lang-item-content,
  .select-lang-item-loader {
    align-items: center;
    display: flex;
  }

  .select-lang-icon {
    height: rem(30);
    margin-right: rem(10);
    width: rem(30);
  }
}

.bp3-transition-container {
  z-index: 99999 !important;
}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .language-selector {

      .language-display {
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

  }

}