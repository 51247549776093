@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.header-bar {

  width: 100%;
  background-color: $white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1061;
  padding: rem(15) 0;
  border-bottom: rem(1) solid rgba($light-grey, 0.4);
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  flex-flow: row nowrap;

  &__anchor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &.scroll {
    box-shadow: 0 rem(5) rem(35) rem(-25) $primaryColor;
  }

  &__inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row nowrap;
    padding: 0 rem($padding);
  }

  &__left,
  &__right {
    width: 50%;
  }

  &__logo {

    width: 100%;

    a {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
    }

    &-icon {
      display: block;
      width: rem(35);
      height: rem(35);
      margin: 0 rem(8) 0 0;

      svg {
        width: rem(35);
        height: rem(35);
      }
    }

    &-text {

      display: block;
      width: rem(125);

      svg {
        width: rem(125);
        height: rem(30);
        color: $navy;
      }
    }

  }

  &__nav-wrapper {

  }

  &__right {

    position: relative;
    z-index: 25;
    display: flex;
    justify-content: flex-end;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    .button {
      display: none;
    }

  }

  &__mobile-btn {
    @include clear-default-appearance;
    cursor: pointer;
    width: rem(35);
    height: rem(28);
    position: relative;

    &.active {
      span:not([class]) {

        &:first-child {
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg);
        }

        &:first-child,
        &:nth-child(3) {
          top: rem(15);
        }

      }
    }

    span:not([class]) {
      display: block;
      width: rem(25);
      height: rem(3);
      background-color: $navy;
      position: absolute;
      left: rem(5);
      transition: transform 0.4s ease, opacity 0.4s ease, top 0.4s ease;
      border-radius: rem(999);

      &:first-child {
        top: rem(4);
      }

      &:nth-child(2) {
        top: rem(12);
      }

      &:nth-child(3) {
        top: rem(20);
      }
    }

  }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .header-bar {

    &__logo {

      &-icon {
        width: rem(40);
        height: rem(40);
        margin: 0 rem(12) 0 0;

        svg {
          width: rem(40);
          height: rem(40);
        }
      }

      &-text {

        width: rem(155);

        svg {
          width: rem(155);
          height: rem(45);
        }
      }

    }


  }

}



/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .header-bar {

    border-bottom: none;
    padding: rem(15) 0;
    transition: top 0.8s ease, opacity 0.7s ease, padding 0.4s ease;
    opacity: 0;
    top: -50%;

    &.loaded {
      top: 0;
      opacity: 1;
    }

    &.scroll {
      padding: rem(18) 0 rem(18) 0;
      border-bottom: rem(1) solid rgba($light-grey, 0.4);
    }

    &__inner {
      max-width: map-get($container-max-widths, 'lg');
      overflow: hidden;
    }

    &__left {
      width: 72%;
      display: flex;
      justify-content: flex-start;
      flex-flow: row nowrap;
      align-content: center;
      align-items: center;
    }

    &__right {

      width: 28%;

      .button {
        display: inline-block;
      }

    }


    &__mobile-btn {
      display: none;
    }

    &__logo {
      width: auto;
      margin: 0 rem(20) 0 0;
      position: relative;

      &-text {

        width: rem(120);

        svg {
          width: rem(120);
          height: rem(30);
        }

      }

      &-icon {
        width: rem(35);
        height: rem(36);
        margin: 0 rem(8) 0 0;
        transition: transform 0.4s ease, width 0.4s ease, height 0.4s ease;

        svg {
          width: rem(35);
          height: rem(36);
        }

      }

      &-text {
        transition: opacity 0.4s ease;
      }

    }

    &.scroll {

      padding: rem(10) 0;

      .header-bar__logo-icon {
        transform: rotate(360deg);
        width: rem(35);
        height: rem(36);

        svg {
          width: rem(35);
          height: rem(36);
        }

      }

      .header-bar__logo-text {
        opacity: 0;
      }

    }

  }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .header-bar {

    &__left {
      width: 75%;
    }

    &__right {
      width: 20%;
    }

    &__inner {
      max-width: map-get($container-max-widths, 'xl');
    }

    &__logo {
      margin: 0 rem(45) 0 0;

      &-text {

        width: rem(160);

        svg {
          width: rem(160);
          height: rem(35);
        }

      }

      &-icon {
        width: rem(50);
        height: rem(51);
        margin: 0 rem(12) 0 0;

        svg {
          width: rem(50);
          height: rem(51);
        }

      }

    }

    &.scroll {

      padding: rem(15) 0;

      .header-bar__logo-icon {
        width: rem(45);
        height: rem(46);

        svg {
          width: rem(45);
          height: rem(46);
        }

      }

    }

  }

}