@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.app-link {

  &--btn {
    display: inline-block;

    img {
      width: 100%;
      max-width: 100%;
      display: block;
    }

  }

}