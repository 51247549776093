@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.active-page {
  padding: rem(66) 0 0 0;
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .active-page {
    padding: rem(76) 0 0 0;
  }


}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .active-page {
    padding: rem(81) 0 0 0;
  }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .active-page {
    padding: rem(91) 0 0 0;
  }


}