@import './variables';
@import './utils';

.h1,
h1 {
  font-size: rem(28);
  line-height: rem(32);
}

.h2
h2 {
  font-size: rem(23);
  line-height: rem(25);
}

.h3,
h3 {
  font-size: rem(20);
  line-height: rem(23);
}

.h4,
h4 {
  font-size: rem(18);
  line-height: rem(20);
}

.h5,
h5 {
  font-size: rem(16);
  line-height: rem(21);
}

.h6,
h6 {
  font-size: rem(14);
  line-height: rem(18);
}

a {
  color: $secondaryColor;
  text-decoration: none;
  outline: 0;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: underline;
    color: $secondaryColor;
  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .h1,
  h1 {
    font-size: rem(43);
    line-height: rem(48);
  }

  .h2
  h2 {
    font-size: rem(35);
    line-height: rem(35);
  }

  .h3,
  h3 {
    font-size: rem(30);
    line-height: rem(30);
  }

  .h4,
  h4 {
    font-size: rem(28);
    line-height: rem(30);
  }

  .h5,
  h5 {
    font-size: rem(17);
    line-height: rem(21);
  }

  .h6,
  h6 {
    font-size: rem(14);
    line-height: rem(18);
  }

}
