@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.partner-banner {

  width: 100%;
  position: relative;
  padding: rem(25) 0 rem(50) 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/bg-animated.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &__inner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0 rem($padding);
    max-width: rem(550);
  }

  &__content,
  &__img {
    width: 100%;
    position: relative;
    z-index: 1;
    text-align: center;
  }

  &__content {
    order: 2;
    padding: rem(30) 0 0 0;
    svg {
      max-width: 70%;
    }
    .content-col {
      &--hero {
        .content-col__title {
          font-size: xx-large;
        }
      }
    }
  }

  &__is-now {
    z-index: 1;
    margin-top: 2rem;
    font-size: xx-large;
    font-weight: bold;
  }

  &__logo {
    max-width: 420px;
  }
  &--app-btns {
    .content-col__cta {
      display: none;
    }
  }

  &__app-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    padding: rem(25) 0 0 0;

    a {
      display: block;
      width: 100%;
      max-width: rem(120);

      &:first-child {
        margin: 0 rem(15) 0 0;
      }
    }

  }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .partner-banner {

    padding: rem(20) 0 rem(40) 0;

    &__inner {
      max-width: 95%;
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }

    &__content {
      order: 0;
      padding: 0;
      svg {
        max-width: 50%;
      }
      .content-col {
        text-align: center;
        &__cta {
          justify-content: center;
        }
      }
    }

    &__is-now {
      margin-left: auto;
      margin-right: auto;
    }
  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .animated-component {

    &.active .partner-banner {

      &:before {
        opacity: 1;
      }

      &__content {
        opacity: 1;
        transform: translateY(0);
      }

      &__img {
        opacity: 1;
        transform: translateY(0);
      }

    }

    .partner-banner {

      &:before {
        transition: opacity 1.5s ease;
        opacity: 0;
      }

      &__img,
      &__content {
        opacity: 0;
        transform: translateY(50%);
        transition: transform 0.8s ease, opacity 0.8s ease;
      }

      &__img {
        opacity: 0;
        transform: translateX(50%);
      }
    }
  }

  .partner-banner {

    padding: rem(25) 0 0 0;

    &:before {
      bottom: -5%;
      width: 100%;
      height: 100%;
      top: inherit;
    }

    &__inner {
      max-width: map-get($container-max-widths, 'lg');
    }

    &__content,
    &__img {
      width: 40%;
    }

    &--app-btns {

      .content-col__cta {
        display: block;
      }

      .partner-banner__app-btns {
        display: none;
      }

    }

    &__img {
      margin-left: -3%;
    }

    &__content {
      margin-top: -6%;
      svg {
        max-width: 70%;
        margin-bottom: -20%;
      }
    }
  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .partner-banner {

    padding: rem(30) 0 0 0;

    &__inner {
      max-width: map-get($container-max-widths, 'xl');
    }

    &__is-now {
      margin-left: 40px;
    }

    &__img {
      margin-left: -3%;
    }

    &__content {
      margin-top: -6%;
      svg {
        margin-bottom: -15%;
      }
      .content-col {
        &__body {
          margin-left: 0;
        }
        &__cta {
          margin-left: 0;
        }
      }
    }
  }
}
