@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.ReactModal__Content {

  max-width: rem(440);
  width: calc(100% - 40px);
  z-index: 1063;
  position: relative;
  border: none;
  padding: 0 !important;
  text-align: center;

  .header {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
  }

  .title {
    font-weight: 600;
    font-size: rem(22);
    line-height: rem(22);
    margin: 0;
    color: $white;
    background-color: $primaryColor;
    width: 100%;
    padding: rem(18) 0;

    &.error {
      background-color: $deep-red;
    }
  }

  .sj-modal-content {
    padding: rem(30) rem(20);
  }

  .subtitle {
    color: $navy;
    font-size: rem(16);
    line-height: rem(25);
    padding: 0 0 rem(25) 0;
  }

}

.ReactModal__Overlay {
  z-index: 1062;
  background-color: rgba($black, 0.85) !important;
}



/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .ReactModal__Content {
    max-width: rem(500);

    .title,
    .subtitle {
      text-align: center;
    }

    .sj-modal-content {
      padding: rem(30);
    }

    .title {
      font-size: rem(25);
      line-height: rem(25);
    }

  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .ReactModal__Content {

    max-width: rem(550);
    width: 100%;

  }

}