@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.footer {

  background-color: $footerBackground;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: rem(50) 0 rem(30) 0;

  &__inner {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__logo,
  &__language-selector,
  &__right {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
  }

  &__logo {

    width: 100%;

    svg {
      width: rem(150);
      height: rem(40);
      color: $primaryColor;
    }
  }

  &__col,
  &__left,
  &__right {
    width: 100%;
  }

  &__right {
    padding: rem(40) 0 0 0;
    margin: rem(5) 0 0 0;
    border-top: rem(1) solid $light-grey;
  }

  &__col {
    margin: 0 0 rem(35) 0;
    text-align: center;
  }

  &__copyright {
    text-align: center;
    font-size: rem(14);
    line-height: rem(21);
    color: $primaryColor;
  }

  &__language-selector {
    padding: rem(20) 0 0 0;
    max-width: rem(460);
  }

  .social {
    margin: rem(30) 0;
  }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .footer {

    &__logo {

      svg {
        width: rem(180);
        height: rem(50);
      }
    }

    &__left {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-items: stretch;
      align-content: stretch;
    }

    &__col {
      width: 33%;
    }

  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .footer {

    padding: rem(50) 0;

    &__left {
      width: 50%;
      order: 2;
      justify-content: flex-end;
    }

    &__right {
      width: 50%;
      border: none;
      padding: 0;
      margin: 0;
      justify-content: flex-start;
    }

    &__logo {

      justify-content: flex-start;

      svg {
        width: rem(200);
        height: rem(52);
      }

    }

    &__language-selector {
      padding: rem(30) 0 0 0;
    }

    &__col {
      width: 33%;
      text-align: left;
    }

  }

}