@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.navigation {

  width: 100%;

  &:before {
    content: '';
    width: 100%;
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    z-index: 0;
    background-color: $black;
    opacity: 0.8;
    transition: opacity 0.4s ease, right 0.5s ease;
  }

  &__wrapper {
    width: rem(320);
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    z-index: 15;
    background-color: $white;
    transition: right 0.4s ease;
    padding: rem(70) rem(30) rem(30) rem(30);
  }

  ul {
    @include reset-list;
    width: 100%;
    padding: 0 0 rem(20) 0;

    li {
      @include reset-list;
      width: 100%;
      padding: 0 0 rem(20) 0;

      a {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(22);
        color: rgba($primaryColor, 0.5);

        &.active {
          color: $primaryColor;
        }
      }
    }

  }

  &.active {

    .navigation__wrapper {
      right: 0;
    }

    &:before {
      right: 0;
    }
  }

  .button {
    margin: 0 0 rem(40) 0;
    width: 100%;
  }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .navigation {
    &__wrapper {
      width: rem(380);
      padding: rem(40) rem(40) rem(40) rem(40);

      ul li {

        padding: 0 0 rem(30) 0;

        a {
          font-size: rem(18);
          line-height: rem(24);
        }
      }

    }

    .button {
      margin: 0 0 rem(50) 0;
    }

  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .navigation {
    padding-top: rem(8);
    padding-bottom: rem(8);
    width: rem(540);

    &.active:before,
    &:before,
    .language-selector,
    .button {
      display: none;
    }

    &.active .navigation__wrapper,
    &__wrapper {
      background-color: transparent;
      position: relative;
      left: 0;
      right: 0;
      transform: translateX(0);
    }

    &__wrapper {

      padding: 0;
      width: 100%;

      ul {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: flex-start;
        flex-flow: row wrap;

        li {
          width: auto;
          padding: 0 rem(25) 0 0;

          &:last-child {
            padding: 0;
          }

          &:first-child a:before {
            background-color: $mint;
          }

          &:nth-child(2) a:before {
            background-color: $secondaryColor;
          }

          &:nth-child(3) a:before {
            background-color: $cherry;
          }

          &:nth-child(4) a:before {
            background-color: $dark-blue;
          }
           &:nth-child(4) a:before {
            background-color: $red;
          }

          a {
            transition: color 0.4s ease;
            position: relative;
            display: inline-block;

            &:before {
              content: '';
              position: absolute;
              left: 50%;
              top: rem(-7);
              transform: translateX(-50%);
              width: 0;
              height: 0;
              opacity: 0;
              border-radius: rem(999);
              background-color: $black;
              transition: opacity 0.4s ease, width 0.4s ease, height 0.4s ease;
            }

            &.active,
            &:hover,
            &:focus {
              text-decoration: none;
              color: $primaryColor;

              &:before {
                opacity: 1;
                width: rem(6);
                height: rem(6);
              }
            }
          }

        }
      }

    }

  }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .navigation {
    width: rem(650);
    &__wrapper {

      ul li {
        padding: 0 rem(30) 0 0;

        a {
          margin-top: rem(12);
          font-size: rem(22);
          line-height: rem(22);

          &:before {
            top: rem(-11);
          }

          &.active,
          &:hover,
          &:focus {

            &:before {
              width: rem(7);
              height: rem(7);
            }
          }
        }

      }

    }

  }

}
