@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.tileButton {
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: rem(15);
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  position: relative;
  padding: rem(30) rem(35) rem(95) rem(35);
  border: rem(1) solid rgba($dark-grey, 0.2);
  box-shadow: rem(1) rem(6) rem(24) rem(-23) $black;
  transition: all ease-in-out 0.2s;

  &:hover {
    box-shadow: rem(15) rem(15) rem(30) rem(-23) $black;
  }
}

.tile {
  width: 100%;
  background-color: $white;
  border-radius: rem(15);
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  position: relative;
  padding: rem(30) rem(35) rem(95) rem(35);
  border: rem(1) solid rgba($dark-grey, 0.2);
  box-shadow: rem(1) rem(6) rem(24) rem(-23) $black;

  &__title {
    width: 100%;
    font-size: rem(28);
    line-height: rem(32);
    letter-spacing: rem(-1);
    padding: rem(20) 0 0 0;
    color: $primaryColor;
    font-weight: 600;
    text-align: center;
  }

  &__content {
    width: 100%;
    font-weight: 400;
    color: rgba($primaryColor, 0.6);
    text-align: center;
    font-size: rem(15);
    line-height: rem(20);
    opacity: 0.8;
  }

  &__icon {
    width: rem(60);
    height: rem(60);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: rem(20);
    opacity: 0.6;
    border-radius: rem(999);
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    svg {
      width: 70%;
      height: 70%;
    }
  }
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .tile {
    align-content: flex-start;
    align-items: flex-start;
    padding: rem(30) rem(35) rem(100) rem(35);

  }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .tile {
    align-content: center;
    align-items: center;

    &__title {
      font-size: rem(32);
      line-height: rem(34);
    }

    &__content {
      font-size: rem(16);
      line-height: rem(22);
    }
  }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .tile {
    align-content: center;
    align-items: center;
    padding: rem(30) rem(35) rem(110) rem(35);

    &__icon {
      bottom: rem(25);
    }
  }
}
