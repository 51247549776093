@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.loader-overlay {
  align-items: center;
  background-color: rgba($black, 0.85);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1062;

  .loader {
    background-color: rgba($black, 0.9);
    border-radius: rem(11);
    padding: rem(25);
    color: rgba($white, 0.7);

    .bp3-spinner {
      margin: 0 0 rem(10) 0;
    }
  }
}
