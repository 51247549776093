@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.tile {
  width: 100%;
  height: 280px;
  background-color: $white;
  border-radius: rem(15);
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  position: relative;
  padding: rem(30) rem(35) rem(35) rem(35);
  border: rem(1) solid rgba($dark-grey, 0.2);
  box-shadow: rem(1) rem(6) rem(24) rem(-23) $black;
  transition: .1s ease-in-out opacity;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &__content {
    width: 100%;
    font-weight: 400;
    color: $primaryColor;
    text-align: center;
    font-size: rem(20);
    line-height: rem(20);
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .tile {
    align-content: flex-start;
    align-items: flex-start;
    padding: rem(30) rem(35) rem(50) rem(35);
  }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .tile {
    align-content: center;
    align-items: center;

    &__title {
      font-size: rem(32);
      line-height: rem(34);
    }

    &__content {
      font-size: rem(20);
      line-height: rem(22);
    }
  }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .tile {
    align-content: center;
    align-items: center;
    padding: rem(95) rem(35) rem(110) rem(35);

    &__icon {
      bottom: rem(25);
    }
  }
}
