@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.social {
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0;

  li {

    list-style: none;
    padding: 0 rem(20) 0 0;
    margin: 0;

    &:last-child {
      padding: 0;
    }

    a {
      display: block;
      width: 100%;
    }

    svg {
      color: $primaryColor;
      width: rem(20);
      height: rem(20);
      transition: color 0.4s ease;
    }

  }


}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .social {

    li {

      a {

        &:hover,
        &:focus {

          outline: 0;

          svg {
            color: $secondaryColor;
          }
        }

      }
    }

  }
}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .social {

    justify-content: flex-start;

    li {

      padding: 0 rem(22) 0 0;

      a {
        svg {
          width: rem(22);
          height: rem(22);
        }
      }

    }

  }

}