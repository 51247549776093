@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.not-found {

  text-align: center;

  &__body {
    padding: rem(80) 0;
  }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .not-found {


    &__body {
      padding: rem(120) 0;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-content: flex-start;
      align-items: flex-start;

      p {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
      }

    }

  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .not-found {

    &__body {

      p {
        max-width: 55%;
      }

    }

  }

}
