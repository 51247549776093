$primary-font:                            'Ubuntu', sans-serif;

$black:                                   #000000;
$white:                                   #fff;
$red:                                     #f00;

$aqua:                                    #00B5F0;
$cherry:                                  #FF5280;
$dark-blue:                               #2574A9;
$dark-grey:                               #979797;
$deep-red:                                #B0113C;
$faded-blue:                              #F8FCFE;
$faded-grey:                              #fbfbfb;
$light-grey:                              #D7D7D7;
$lighter-grey:                            #E8E8E8;
$mint:                                    #00D9AA;
$navy:                                    #33374D;

$primaryColor:                            $navy;
$secondaryColor:                          $aqua;
$buttonColor:                             $navy;

$tertiaryColor:                           #333333;
$altColor:                                #3bb2ea;
$tileShadowColor:                         #ccc;
$footerLinkHoverColor:                    #d3dae2;
$buttonModalCloseColor:                   #424242;
$buttonDisabledColor:                     $lighter-grey;
$buttonHoverColor:                        $altColor;
$buttonTextColor:                         $white;
$buttonDisabledTextColor:                 $white;
$buttonDisabledBorderColor:               #999;
$footerBackground:                        #f6f6f6;
$modalUnderline:                          #808080;
$invalidFieldColor:                       #feccd7;
$borderColor:                             #d2d2d2;
$inputTextColor:                          #495057;

$padding:         20;
$max-width:       1370;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1240px
) !default;