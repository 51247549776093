@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.input-with-validation {
  position: relative;

  label {
    width: 100%;
    font-weight: 600;
    margin: 0 0 rem(10) 0;
    font-size: rem(16);
    line-height: rem(16);
  }

  .form-input {
    -webkit-appearance: none;
    border: rem(3) solid $primaryColor;
    border-radius: rem(999);
    color: $primaryColor;
    display: block;
    font-size: rem(14);
    line-height: rem(14);
    font-weight: 400;
    margin: 0 0 rem(20) 0;
    outline: 0 !important;
    box-shadow: none;
    padding: rem(15) rem(20) rem(15) rem(20);
    width: 100%;
    text-align: center;
    transition: background-color 0.4s ease, border 0.4s ease;

    &::-webkit-input-placeholder {
      color: rgba($primaryColor, 0.3);
    }

    &:-ms-input-placeholder {
      color: rgba($primaryColor, 0.3);
    }

    &::placeholder {
      color: rgba($primaryColor, 0.3);
    }

    &.error {
      border: rem(3) solid $cherry;
      background-color: rgba($cherry, 0.1);
      color: $white;

      &:focus {
        color: $primaryColor;
      }
    }

    &.error:focus {
      background-color: $white;
    }

  }

  textarea.form-input {
    border-radius: rem(23);
  }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .input-with-validation {

    label {
      margin: 0 0 rem(15) 0;
      font-size: rem(17);
      line-height: rem(17);
    }

    .form-input {
      padding: rem(16) rem(20) rem(16) rem(20);
    }

  }

}