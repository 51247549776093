@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

$delay:  0.1;
$limit:  20;

.info-tiles-view {

  paddingTop: rem(30);

  .info-tiles-view-wrapper {
    color: $primaryColor;
    margin: 0 auto;
    max-width: rem(450);
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
  }

  .title {
    text-align: center;
    width: 100%;
  }

  .subtitle {
    text-align: center;
    width: 90%;
    margin: rem(20) 0 0 0;
    padding: 0;
  }

  &__footer {
    font-size: rem(15);
    line-height: rem(22);
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: rem(50) rem($padding) 0 rem($padding);
  }

  &__grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    margin: rem(40) 0 0 0;
    padding: 0 rem($padding);
  }

  &__item {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    margin: 0 0 rem(30) 0;
    box-shadow: 15px 15px 35px 5px rgba(0,0,0,0.2);
    border-radius: 30px;

    .text-link {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin: 0;
    }
  }
}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .info-tiles-view {

    padding: rem(30) 0;

    .info-tiles-view-wrapper {
      max-width: map-get($container-max-widths, 'md');
    }

    .subtitle {
      width: 85%;
    }

    &__grid {

      justify-content: space-between;
      margin: rem(10) 0 0 0;
    }

    &__item {

      width: 48%;

      &:last-child {
        margin: 0 0 rem(30) 0;
      }

    }

    &__footer {
      font-size: rem(16);
      line-height: rem(25);
      padding: rem(30) rem($padding) 0 rem($padding);
    }
  }
}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .animated-component {

    &.active .info-tiles-view {

      &__item {
        opacity: 1;
        transform: translateY(0);
      }

    }

    .info-tiles-view {

      &__item {
        transition: opacity 0.6s ease, transform 0.6s ease;
        opacity: 0;
        transform: translateY(20%);
        transition-delay: #{$delay}s;

        @for $i from 2 through $limit {
          &:nth-child(#{$i}) {
            transition-delay: #{$delay * $i}s;
          }
        }
      }
    }
  }

  .info-tiles-view {

    padding: rem(30) 0;

    .info-tiles-view-wrapper {
      max-width: map-get($container-max-widths, 'lg');
    }

    .subtitle {
      width: 80%;
      margin: rem(25) 0 0 0;
    }

    &__grid {
      justify-content: center;
    }

    &__item {
      width: 32%;
      min-height: rem(230);
      margin: 0 2% 2% 0;

      &:nth-child(3n + 3) {
        margin: 0 0 2% 0;
      }

      &:last-child:not(3n + 3) {
        margin: 0 2% 2% 0;
      }
    }

    &__footer {
      max-width: 75%;
      font-size: rem(17);
      line-height: rem(27);
    }
  }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .info-tiles-view {

    padding: rem(10) 0;

    .info-tiles-view-wrapper {
      max-width: map-get($container-max-widths, 'xl');
    }

    .subtitle {
      width: 100%;
      max-width: rem(820);
      margin: rem(30) 0 0 0;
    }

    &__grid {
      margin: rem(10) 0 0 0;
    }

    &__item {
      width: 32%;
    }

    &__footer {
      padding: rem(50) rem($padding) 0 rem($padding);
    }
  }
}
