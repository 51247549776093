@import '@/styles/imports/variables';

.nav-link {
  &.button {
    color: $buttonTextColor;
    display: inline-block;

    &:hover {
      background-color: $buttonHoverColor;
      border-color: $buttonHoverColor;
    }
  }
}
