@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.content-banner {

  background-image: url('../../../public/images/app-link-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: rem(40) rem($padding);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  text-align: center;

  svg {
    display: inline-block;
    width: rem(290);
    height: rem(33);
  }

  h1 {
    width: 100%;
    display: block;
    font-weight: 300;
  }

}



/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .content-banner {

    padding: rem(70) rem($padding);

    svg {
      width: rem(315);
      height: rem(35);
    }

    h1 {
      font-size: rem(35);
      line-height: rem(40);
    }

  }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .content-banner {

    padding: rem(90) rem($padding);

    svg {
      width: rem(395);
      height: rem(47);
    }

    h1 {
      max-width: rem(660);
      font-size: rem(42);
      line-height: rem(47);
    }

  }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

  .content-banner {

    padding: rem(110) rem($padding);

    svg {
      width: rem(477);
      height: rem(57);
    }

    h1 {
      max-width: rem(660);
      font-size: rem(50);
      line-height: rem(60);
    }

  }

}